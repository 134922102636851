<template>
  <div class="work-with-us">
    <div class="container">
      <h1>Venha trabalhar conosco!</h1>
      <section class="how-works">
        <div>
          <h2>Como <span>Funciona</span></h2>
          <p>Após baixar o app em uma das lojas, vá em registrar. Dentro de instantes seu perfil será habilitado.</p>
        </div>
        <div>
          <!-- <img alt="" src="~@/assets/image-how-works.png"> -->
        </div>
      </section>
      <section class="now-is-simple">
        <div>
          <!-- <img alt="" src="~@/assets/image-simple-app.png"> -->
        </div>
        <div>
          <h2>Agora é <span>Simples</span></h2>
          <p>Com a conta validada, os leads vão chegar em seu dispositivo, por isso fique atento. Aceite os leads, faça o atendimento e conclua suas vendas muito mais rápido e dinâmico.</p>
        </div>
      </section>
      <section class="download-app">
        <h2>Não perca tempo. Baixe nosso app agora!</h2>
        <div>
          <a href="https://play.google.com/store/apps/details?id=br.com.politiz.acp" target="_blank">
            <img alt="" src="~@/assets/logo-android.svg">
          </a>
          <a href="https://apps.apple.com/br/app/imob-de-bolso/id1524592366" target="_blank">
            <img alt="" src="~@/assets/logo-apple.svg">
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WorkWithUs'
}
</script>

<style lang="scss" scoped>
  .work-with-us {
    padding-top: 64px;
    h1 {
      font-size: var(--size-h1);
      line-height: 70px;
      color: var(--secundary-black);
      font-weight: 700;
      margin:100px 0 45px;
      max-width: 565px;
    }
    h2 {
      font-size: var(--size-h2);
      line-height: 48px;
      color: var(--secundary-black);
      font-weight: 400;
      margin-bottom: 60px;
      span {
        display: block;
        font-weight: 700;
        line-height: 32px;
      }
    }

    p {
      font-weight: 300;
      line-height: 29px;
      font-size: var(--size-large);
      color: var(--atomic);
      margin-bottom: 16px;
    }

    .container {
      max-width: 1200px;
    }

    .how-works {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div:nth-child(1) {
        max-width: 390px;
        margin-right: 120px;
      }

      div:nth-child(2) {
        max-width: 500px;
        background: url("~@/assets/bg-points.svg") center left no-repeat;
        padding: 0 0 0 140px;
        img {
          max-width: 302px;
          width: 100%;
          height: auto;
        }
      }
    }

    .now-is-simple {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div:nth-child(1) {
        max-width: 500px;
        background: url("~@/assets/bg-points.svg") center left no-repeat;
        padding: 0 0 0 140px;
        img {
          max-width: 302px;
          width: 100%;
          height: auto;
        }

      }

      div:nth-child(2) {
        max-width: 390px;
        margin-left: 190px;
      }
    }

    .download-app {
      margin: 120px 0 200px;
      padding: 0 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: url("~@/assets/bg-download-02.svg") bottom center no-repeat,
      url("~@/assets/bg-download-01.svg") center 120px no-repeat,
      url("~@/assets/bg-points.svg") top center no-repeat;
      min-height: 490px;

      h2 {
        font-weight: 700;
        max-width: 370px;
        padding: 90px 0 0;
      }

      img {
        margin: 60px 0 0 50px;
      }
    }

    @media (max-width: 768px) {
      padding-top: 0px;
      h1 {
        font-size: 46px;
        line-height: 56px;
        margin: 80px 0 20px;
      }

      h2 {
        margin-bottom: 32px;
        margin-top: 32px;
      }

      .how-works {
        flex-direction: column;

        h2 {
          margin-bottom: 32px;
        }

        div:nth-child(1) {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 32px;
        }
      }

      .now-is-simple {
        flex-direction: column-reverse;

        div:nth-child(2) {
          max-width: 100%;
          margin-left: 0;
          margin-bottom: 32px;
        }
      }

      .download-app {
        margin: 40px 0 60px;
        flex-direction: column;
        background: var(--grey-custom);
        padding: 32px 24px;
        border-radius: 20px;
        align-items: flex-start;
        min-height: 340px;

        img {
          margin: 0px 0 0 0px;
          max-width: 120px;
        }

        h2 {
          padding: 0 0 0 0;
          margin: 0 0;
        }
      }

    }
  }
</style>
